import React from "react";
import {useTranslation} from "react-i18next";
import M from 'materialize-css'
const Services = () => {

    const {t} = useTranslation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
        M.AutoInit()
        document.title = "Tashtech | Services";
    }, []);
    return (
        <React.Fragment>

            {/* contact bannner start*/}
            <section className='contactpage'>
                <div className='row'>
                    <div className='col s12 m12'>
                        <div className='contactbanner'>
                            <figure>
                                <h3 className='contact-text'>tashtech</h3>
                                <h3 className='contact-text1'>{t("services-banner.2")}</h3>
                                <h2 className='home-slogan1 service-slogan'>{t("services-banner.3")}</h2>
                                <img src='./images/turning-image.jpg' alt='turning'/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            {/* contact bannner end */}

            {/* our service start */}
            <section className='service-section'>
                <div className='service-area'>
                    <div className='container'>
                        <div className='row'>
                            <div className='title service-title'>
                                <h2>{t("services-cards.0")}</h2>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col s12 m6 l3'>
                                <div className='service-box text-center'>
                                    <div className='service-icon'>
                                        <figure>
                                            <img src='./images/factory-automation-service.svg' alt='factory automation'/>
                                        </figure>
                                    </div>
                                    <div className='service-content'>
                                        <h3>{t("services-cards.7")}</h3>
                                        <p>{t("services-cards.8")}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col s12 m6 l3'>
                                <div className='service-box text-center'>
                                    <div className='service-icon'>
                                        <figure>
                                            <img src='./images/briefing.svg' alt='pumps'/>
                                        </figure>
                                    </div>
                                    <div className='service-content'>
                                        <h3>{t("services-cards.34")}</h3>
                                        <p>{t("services-cards.35")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col s12 m6 l3'>
                                <div className='service-box text-center'>
                                    <div className='service-icon'>
                                        <figure>
                                            <img src='./images/eng.svg' alt='paints'/>
                                        </figure>
                                    </div>
                                    <div className='service-content'>
                                        <h3>{t("services-cards.13")}</h3>
                                        <p>{t("services-cards.14")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col s12 m6 l3'>
                                <div className='service-box text-center'>
                                    <div className='service-icon'>
                                        <figure>
                                            <img src='./images/fabrication-service.svg' alt='fabrication'/>
                                        </figure>
                                    </div>
                                    <div className='service-content'>
                                        <h3>{t("services-cards.1")}</h3>
                                        <p>{t("services-cards.2")}</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='row'>
                            <div className='col s12 m6 l3'>
                                <div className='service-box text-center'>
                                    <div className='service-icon'>
                                        <figure>
                                            <img src='./images/control-service.svg' alt='control system'/>
                                        </figure>
                                    </div>
                                    <div className='service-content'>
                                        <h3>{t("services-cards.5")}</h3>
                                        <div
                                            style={{
                                            fontSize: '14px'
                                        }}>
                                            <li>{t("services-cards.61")}</li>
                                            <li>{t("services-cards.62")}</li>
                                            <li>{t("services-cards.63")}</li>
                                            <li>{t("services-cards.64")}</li>
                                            <li>{t("services-cards.65")}</li>
                                            <p>{t("services-cards.6")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col s12 m6 l3'>
                                <div className='service-box text-center'>
                                    <div className='service-icon'>
                                        <figure>
                                            <img src='./images/capacitor.svg' alt='Pneumatics'/>
                                        </figure>
                                    </div>
                                    <div className='service-content'>
                                        <h3>{t("services-cards.29")}</h3>
                                        <p>{t("services-cards.30")}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col s12 m6 l3'>
                                <div className='service-box text-center'>
                                    <div className='service-icon'>
                                        <figure>
                                            <img src='./images/control-service.svg' alt='control system'/>
                                        </figure>
                                    </div>
                                    <div className='service-content'>
                                        <h3>{t("services-cards.31")}</h3>
                                        <div>
                                            <li>{t("services-cards.311")}</li>
                                            <li>{t("services-cards.312")}</li>
                                            <li>{t("services-cards.313")}</li>
                                            <li>{t("services-cards.314")}</li>
                                            <li>{t("services-cards.315")}</li>
                                        </div>
                                    </div>
                                </div>
                            </div>

                         <div className='col s12 m6 l3'>
                                <div className='service-box text-center'>
                                    <div className='service-icon'>
                                        <figure>
                                            <img src='./images/seals.svg' alt='solar power'/>
                                        </figure>
                                    </div>
                                    <div className='service-content'>
                                        <h3>{t("services-cards.25")}</h3>
                                        <p>{t("services-cards.26")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col s12 m6 l3'>
                                <div className='service-box text-center'>
                                    <div className='service-icon'>
                                        <figure>
                                            <img src='./images/solarpower-service.svg' alt='solar power'/>
                                        </figure>
                                    </div>
                                    <div className='service-content'>
                                        <h3>{t("services-cards.17")}</h3>
                                        <p>{t("services-cards.18")}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col s12 m6 l3'>
                                <div className='service-box text-center'>
                                    <div className='service-icon'>
                                        <figure>
                                            <img src='./images/machining.svg' alt='Machining'/>
                                        </figure>
                                    </div>
                                    <div className='service-content'>
                                        <h3>{t("services-cards.19")}</h3>
                                        <p>{t("services-cards.20")}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col s12 m6 l3'>
                                <div className='service-box text-center'>
                                    <div className='service-icon'>
                                        <figure>
                                            <img src='./images/pneumatics.svg' alt='Pneumatics'/>
                                        </figure>
                                    </div>
                                    <div className='service-content'>
                                        <h3>{t("services-cards.21")}</h3>
                                        <p>{t("services-cards.22")}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col s12 m6 l3'>
                                <div className='service-box text-center'>
                                    <div className='service-icon'>
                                        <figure>
                                            <img src='./images/paint.svg' alt='Paint'/>
                                        </figure>
                                    </div>
                                    <div className='service-content'>
                                        <h3>{t("services-cards.23")}</h3>
                                        <p>{t("services-cards.24")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
 <div className='col s12 m6 l3'>
                                <div className='service-box text-center'>
                                    <div className='service-icon'>
                                        <figure>
                                            <img src='./images/welding.svg' alt='solar power'/>
                                        </figure>
                                    </div>
                                    <div className='service-content'>
                                        <h3>{t("services-cards.32")}</h3>
                                        <p>{t("services-cards.33")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col s12 m6 l3'>
                                <div className='service-box text-center'>
                                    <div className='service-icon'>
                                        <figure>
                                            <img src='./images/wiring.svg' alt='Machining'/>
                                        </figure>
                                    </div>
                                    <div className='service-content'>
                                        <h3>{t("services-cards.27")}</h3>
                                        <p>{t("services-cards.28")}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col s12 m6 l3'>
                                <div className='service-box text-center'>
                                    <div className='service-icon'>
                                        <figure>
                                            <img src='./images/hardware-service.svg' alt='hardware'/>
                                        </figure>
                                    </div>
                                    <div className='service-content'>
                                        <h3>{t("services-cards.9")}</h3>
                                        <p>{t("services-cards.10")}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col s12 m6 l3'>
                                <div className='service-box text-center'>
                                    <div className='service-icon'>
                                        <figure>
                                            <img src='./images/hydraulics-service.svg' alt='hydraulics'/>
                                        </figure>
                                    </div>
                                    <div className='service-content'>
                                        <h3>{t("services-cards.11")}</h3>
                                        <p>{t("services-cards.12")}</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* last row */}
                        <div className='row'>
                           

                            <div className='col s12 m6 l3 offset-l4'>
                                <div className='service-box text-center'>
                                    <div className='service-icon'>
                                        <figure>
                                            <img src='./images/pumps-service.svg' alt='Machining'/>
                                        </figure>
                                    </div>
                                    <div className='service-content'>
                                        <h3>{t("services-cards.15")}</h3>
                                        <p>{t("services-cards.16")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            {/* our service end */}
        </React.Fragment>
    );
};

export default Services;
