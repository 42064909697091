import React from "react";
import Carousal from "react-multi-carousel";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import M from 'materialize-css'
const Home = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init();
    M.AutoInit()
    document.title = "Tashtech Industrial Solutions";
  }, []);
  return (
    <React.Fragment>
      <section className='homebanner'>
        <div className='homebannerpadding'>
          <div className='row'>
            <div className='col s12 m12 padding-zero'>
              <Carousal
                additionalTransfrom={0}
                autoPlay={true}
                autoPlaySpeed={5000}
                centerMode={false}
                className=''
                containerClass='container-with-dots'
                draggable
                focusOnSelect={false}
                infinite
                minimumTouchDrag={80}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 1,
                    partialVisibilityGutter: 40,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                }}
                showDots={false}
                slidesToSlide={0}
                swipeable>
                <div>
                  <div className='home-text'>{t("home-banner.1")}</div>
                  <div className='home-text1'>{t("home-banner.2")}</div>
                  <h2 className='home-slogan'>{t("home-banner.3")}</h2>
                  <figure>
                    <img src='./images/homebanner1.jpg' alt='home banner' />
                  </figure>
                </div>
                <div>
                  <div className='home-card'>
                    <h3>{t("home-banner.4")}</h3>
                    <p>{t("home-banner.5")}</p>
                    <Link to='/services'>
                      {t("home-banner.7")} <i className='fas fa-arrow-right'></i>
                    </Link>
                  </div>
                  <div className='home-text'>{t("home-banner.1")}</div>
                  <div className='home-text1'>{t("home-banner.2")}</div>

                  <figure>
                    <img src='./images/home2.jpeg' alt='home banner' />
                  </figure>
                </div>
                <div>
                  <div className='home-text'>{t("home-banner.1")}</div>
                  <div className='home-text1'>{t("home-banner.2")}</div>
                  <h2 className='home-slogan'>{t("home-banner.6")}</h2>
                  <figure>
                    <img src='./images/home3.jpeg' alt='home banner' />
                  </figure>
                </div>
              </Carousal>
            </div>
          </div>
        </div>
      </section>

      {/* idea html start */}
      <section className='full-width ideassection section-padding'>
        <div className='container'>
          <div className='row'>
            <div className='full-width'>
              <div className='row'>
                <div className='col s12 m6 l6'>
                  <div
                    className='full-width ideacontent'
                    data-aos='fade-right'
                    data-aos-offset='300'
                    data-aos-easing='ease-in-sine'>
                    <h2>
                      <span className='span1'>
                        {t("home-idea.1")}
                        {t("home-idea.2")}
                      </span>
                      <span className='span2'>{t("home-idea.3")}</span>
                    </h2>
                  </div>
                </div>
                <div className='col s12 m6 l6'>
                  <div className='full-width ideaimage'>
                    <figure className='box-shadow'>
                      <img
                        src='./images/turning-image.jpg'
                        alt='intogreatproduct'
                        title='intogreatproduct'
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* idea html end */}

      {/* why steel solution start*/}
      <section className='whyus full-width'>
        <div className='container'>
          <div className='row'>
            <div className='row'>
              <div className='col s12 12 m6 l6'>
                <div className='full-width whyusimage'>
                  <figure className='box-shadow'>
                    <img src='./images/fabrication.jpg' alt='why-us' title='why-us' />
                  </figure>
                </div>
              </div>
              <div className='col s12 12 m6 l6'>
                <div className='full-width whyuscontent'>
                  <h3>{t("home-choose.1")}</h3>
                  <p>
                    <li>{t("home-choose.2")}</li>
                    <li>{t("home-choose.3")}</li>
                    <li>{t("home-choose.4")}</li>
                    <li>{t("home-choose.5")}</li>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* why end */}
      <section className='about-company section-padding'>
        <div className='about-company-container'>
          <div className='row'>
            <div className='col l6 m12 s12'>
              <div className='info-image'>
                <figure>
                  <img src='./images/company.png' alt='aboutcompanyimage' />
                </figure>
              </div>
            </div>
            <div className='col l6 m12 s12'>
              <div
                className='company-info-area'
                data-aos='fade-up'
                data-aos-duration='3000'>
                <div className='section-title'>
                  <h6>{t("home-about.1")}</h6>
                </div>
                <div className='companyquote'>
                  <h2>{t("home-about.2")}</h2>
                </div>
              </div>
              <p
                className='company-desc'
                data-aos='fade-right'
                data-aos-offset='300'
                data-aos-easing='ease-in-sine'>
                {t("home-about.3")}
              </p>
              <p
                className='points'
                data-aos='fade-right'
                data-aos-offset='300'
                data-aos-easing='ease-in-sine'>
                {t("home-about.4")}
              </p>
              <p
                className='points'
                data-aos='fade-right'
                data-aos-offset='300'
                data-aos-easing='ease-in-sine'>
                {t("home-about.5")}
              </p>
              <p
                className='points'
                data-aos='fade-right'
                data-aos-offset='300'
                data-aos-easing='ease-in-sine'>
                {t("home-about.6")}
              </p>

              <div className='row founded'>
                <div className='col l12 m6 s12'>
                  <div className='yeartext'>{t("home-about.7")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* our service start */}
      <section className='service-section'>
        <div className='service-area'>
          <div className='container'>
            <div className='row'>
              <div className='title service-title'>
                <h2>{t("home-Our-Top-Services.0")}</h2>
              </div>
            </div>
            <div className='row'>
              <div className='col s12 m6 l3'>
                <div
                  className='service-box text-center'
                  data-aos='fade-right'
                  data-aos-offset='300'
                  data-aos-duration='2000'
                  data-aos-delay='500'
                  data-aos-easing='ease-in-sine'>
                  <div className='service-icon'>
                    <figure>
                      <img
                        src='./images/factory-automation-service.svg'
                        alt='fabrication'
                      />
                    </figure>
                  </div>
                  <div className='service-content'>
                    <h3>{t("home-Our-Top-Services.1")}</h3>
                    <p>{t("home-Our-Top-Services.2")}</p>
                  </div>
                </div>
              </div>
              <div className='col s12 m6 l3'>
                <div
                  className='service-box text-center'
                  data-aos='fade-right'
                  data-aos-offset='300'
                  data-aos-duration='2000'
                  data-aos-delay='300'
                  data-aos-easing='ease-in-sine'>
                  <div className='service-icon'>
                    <figure>
                      <img src='./images/briefing.svg' alt='cablings' />
                    </figure>
                  </div>
                  <div className='service-content'>
                    <h3>{t("services-cards.34")}</h3>
                    <p>{t("services-cards.35")}</p>
                  </div>
                </div>
              </div>
              <div className='col s12 m6 l3'>
                <div
                  className='service-box text-center'
                  data-aos='fade-right'
                  data-aos-offset='300'
                  data-aos-duration='2000'
                  data-aos-easing='ease-in-sine'>
                  <div className='service-icon'>
                    <figure>
                      <img src='./images/fabrication-service.svg' alt='control system' />
                    </figure>
                  </div>
                  <div className='service-content'>
                    <h3>{t("home-Our-Top-Services.5")}</h3>
                    <p>{t("home-Our-Top-Services.6")}</p>
                  </div>
                </div>
              </div>
              <div className='col s12 m6 l3'>
                <div
                  className='service-box text-center'
                  data-aos='fade-right'
                  data-aos-offset='300'
                  data-aos-duration='2000'
                  data-aos-easing='ease-in-sine'>
                  <div className='service-icon'>
                    <figure>
                      <img src='./images/capacitor.svg' alt='control system' />
                    </figure>
                  </div>
                  <div className='service-content'>
                    <h3>{t("services-cards.29")}</h3>
                    <p>{t("services-cards.30")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* our service end */}
    </React.Fragment>
  );
};

export default Home;
