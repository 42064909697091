import React from "react";
import Aos from "aos";
import { Link } from "react-router-dom";
import M from "materialize-css";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
    Aos.init();
  }, []);

  const [email, setEmail] = React.useState("");
  const handleSubmit = async () => {
    try {
      document.getElementById("submit").innerHTML = "sending...";
      const response = await fetch(
        "https://v1.nocodeapi.com/bikash7772/google_sheets/LfhtlUvWDnRyxkpy?tabId=Sheet2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify([[email, new Date().toLocaleString()]]),
        }
      );
      await response.json();
      M.toast({
        html: "Thank you for your response",
        classes: "rounded tos",
      });
      document.getElementById("submit").innerHTML = "submit";
      setEmail("");
    } catch (error) {}
  };
  return (
    <React.Fragment>
      {/* parallax start */}
      <section className='parallexsection'>
        <div className='parallax-container'>
          <div className='parallax'>
            <img src='images/parallax.jpg' alt='parallax' />
          </div>
          <div className='parallax-content'>
            <div className='container'>
              <div className='row'>
                <div className='col m12 s12 l4'>
                  <h3 data-aos='zoom-in' data-aos-duration='1000'>
                    {t("home-parallax.1")}
                  </h3>
                </div>
                <div
                  className='col m12 s12 l4'
                  data-aos='zoom-in'
                  data-aos-duration='1500'>
                  <span className='callus'>
                    <i className='material-icons'>phone</i>
                    {t("home-parallax.2")}
                  </span>
                  <a href='tel:+27782280971'>+27782280971</a>
                </div>
                <div
                  className='col m12 s12 l4'
                  data-aos='zoom-in'
                  data-aos-duration='2000'>
                  <span className='emailus'>
                    <i className='material-icons'>mail</i> {t("home-parallax.3")}
                  </span>
                  <div className='parallex-mail'>
                    <a href='mailto:info@tashtech.co.za'>info@tashtech.co.za</a>
                    <a href='mailto:sales@tashtech.co.za'>sales@tashtech.co.za</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* parallax end */}
      <footer>
        <div className='footer-top'>
          <div className='container'>
            <div className='row'>
              <div className='col m3 s6 xs12 segment-one md-mb-30 sm-mb-30'>
                <h3>{t("footer.1")}</h3>
                <p>{t("footer.2")} </p>
              </div>

              <div className='col m3 s6 xs12 segment-two md-mb-30 sm-mb-30'>
                <h2>{t("footer.3")}</h2>
                <ul>
                  <li>
                    <Link to='/'>{t("footer.4")}</Link>
                  </li>
                  <li>
                    <Link to='/about'>{t("footer.5")}</Link>
                  </li>
                  <li>
                    <Link to='/services'>{t("footer.6")}</Link>
                  </li>
                  <li>
                    <Link to='/contact'>{t("footer.7")}</Link>
                  </li>
                </ul>
              </div>
              <div className='col m3 s6 xs12 segment-three sm-mb-30'>
                <h2>{t("footer.8")}</h2>
                <p>{t("footer.9")}</p>
                <a
                  href='https://www.facebook.com/Tashtech-Industrial-Solutions-101509135257466/'
                  target='_blank'
                  rel='noreferrer'>
                  <i className='fab fa-facebook'></i>
                </a>
                <a
                  href='https://instagram.com/tashtechindustrial?igshid=1a78y7c3bhrt9'
                  target='_blank'
                  rel='noreferrer'>
                  <i className='fab fa-instagram'></i>
                </a>
                <a href='/'>
                  <i className='fab fa-linkedin'></i>
                </a>
                <a
                  href='https://twitter.com/TashtechS?s=09'
                  target='_blank'
                  rel='noreferrer'>
                  <i className='fab fa-twitter'></i>
                </a>
              </div>
              <div className='col m3 s6 xs12 segment-four sm-mb-30'>
                <h2>{t("footer.10")}</h2>
                <p>{t("footer.11")}</p>
                <p>{t("footer.12")}</p>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}>
                  <input
                    type='email'
                    className='formemail'
                    placeholder='Enter Email address'
                    onClick={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "Enter Email address")}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                  />
                  <input type='submit' className='formbtn' id='submit' />
                </form>
              </div>
            </div>
          </div>
        </div>
        <p className='footer-bottom-text'>
          All rights Reserved &copy; {new Date().getFullYear()}
        </p>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
