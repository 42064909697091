import React from "react";
import M from "materialize-css";
import { useTranslation } from "react-i18next";
const Contact = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
M.AutoInit()
    document.title = "Tashtech | Contact";
  }, []);
  const [lname, setLName] = React.useState("");
  const [fname, setFName] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [email, setEmail] = React.useState("");
  const submitttData = async () => {
    try {
      document.getElementById("submit").innerHTML = "sending...";
      const response = await fetch(
        "https://v1.nocodeapi.com/bikash7772/google_sheets/LfhtlUvWDnRyxkpy?tabId=Sheet1",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify([
            [fname, lname, email, contact, message, new Date().toLocaleString()],
          ]),
        }
      );
      await response.json();
      M.toast({
        html: "Thank you for your enquiry. Our team will be in touch",
        classes: "rounded tos",
      });
      document.getElementById("submit").innerHTML = "submit";
      setContact("");
      setEmail("");
      setFName("");
      setLName("");
      setMessage("");
    } catch (error) {}
  };
  return (
    <React.Fragment>
      {/* contact bannner start*/}
      <section className='contactpage'>
        <div className='row'>
          <div className='col s12 m12'>
            <div className='contactbanner'>
              <figure>
                <h3 className='contact-text'>tashtech</h3>
                <h3 className='contact-text1'>{t("contact-banner.2")}</h3>
                <h2 className='home-slogan1'>{t("services-banner.3")}</h2>
                <img src='./images/contactbanner.jpg' alt='' />
              </figure>
            </div>
          </div>
        </div>
      </section>
      {/* contact bannner end */}

      {/* maps start */}
      <iframe
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3455.3950509307347!2d30.908605314337372!3d-29.996810981899632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ef7ad26f23d3a77%3A0xa6be28e6b3dd26d8!2s6%20Araucaria%20Pl%2C%20Lotus%20Park%2C%204111%2C%20South%20Africa!5e0!3m2!1sen!2sin!4v1610556474653!5m2!1sen!2sin'
        width='100%'
        height='450'
        title='map'
        frameBorder='0'
        style={{ border: "0" }}
        aria-hidden='false'
        tabIndex='0'></iframe>
      {/* maps end */}

      {/* contact from start */}
      <section className='contactform'>
        <div className='container'>
          <div className='row'>
            <div className='col l12'></div>
          </div>
          <div className='row'>
            <div className='col s12 m7'>
              <form
                className='contact-form'
                onSubmit={(e) => {
                  e.preventDefault();
                  submitttData();
                }}>
                <div className='col l12'>
                  <div className='form-group'>
                    <textarea
                      type='text'
                      placeholder='Enter Message'
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "Enter Message")}
                      cols='30'
                      rows='9'
                      required
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}></textarea>
                  </div>
                </div>
                <div className='col l6'>
                  <div className='form-group'>
                    <input
                      type='text'
                      placeholder='Enter First Name'
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "Enter First Name")}
                      onChange={(e) => setFName(e.target.value)}
                      value={fname}
                      required
                    />
                  </div>
                </div>
                <div className='col l6'>
                  <div className='form-group'>
                    <input
                      type='text'
                      placeholder='Enter Last Name'
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "Enter Last Name")}
                      required
                      onChange={(e) => setLName(e.target.value)}
                      value={lname}
                    />
                  </div>
                </div>
                <div className='col l6'>
                  <div className='form-group'>
                    <input
                      type='email'
                      placeholder='Enter Email Address'
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "Enter Email Address")}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                </div>
                <div className='col l6'>
                  <div className='form-group'>
                    <input
                      type='number'
                      placeholder='Enter Contact Number'
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "Enter Contact Number")}
                      onChange={(e) => setContact(e.target.value)}
                      value={contact}
                      required
                    />
                  </div>
                </div>
                <div className='col l6'>
                  <div className='from-group'>
                    <button type='submit' id='submit'>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className='col m4 offset-l1 s12'>
              <div className='contact-address'>
                <div className='address-info'>
                  <span>
                    <i className='far fa-building'></i>
                  </span>
                  <div className='address-content'>
                    <h4>6 Araucaria Place</h4>
                    <p>Lotus Park Isipingo 4133</p>
                    <p>Durban</p>
                  </div>
                </div>
                <div className='address-info'>
                  <span>
                    <i className='fas fa-mobile-alt'></i>
                  </span>
                  <div className='address-content'>
                    <a href='tel:+27782280971'>+27782280971</a>
                  </div>
                </div>
                <div className='address-info'>
                  <span>
                    <i className='far fa-envelope'></i>
                  </span>
                  <div className='address-content'>
                    <a href='mailto:info@tashtech.co.za'>info@tashtech.co.za</a>
                    <a href='mailto:sales@tashtech.co.za'>sales@tashtech.co.za</a>
                  </div>
                </div>
              </div>
              <section className='socialmediaicon'>
                <div className='social-menu container'>
                  <ul>
                    <li>
                      <a
                        href='https://www.facebook.com/Tashtech-Industrial-Solutions-101509135257466/'
                        target='_blank'
                        rel='noreferrer'>
                        <i className='fab fa-facebook'></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://twitter.com/TashtechS?s=09'
                        target='_blank'
                        rel='noreferrer'>
                        <i className='fab fa-twitter'></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://instagram.com/tashtechindustrial?igshid=1a78y7c3bhrt9'
                        target='_blank'
                        rel='noreferrer'>
                        <i className='fab fa-instagram'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      {/* contact from end */}
    </React.Fragment>
  );
};

export default Contact;
