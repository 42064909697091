import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./i18n";
import "aos/dist/aos.css";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense
      fallback={
        <div className='loader suspense'>
          <h3>loading...</h3>
        </div>
      }>
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
