import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Home from "./components/views/Home";
import About from "./components/views/About";
import Client from "./components/views/Client";
import Services from "./components/views/Services.js";
import Contact from "./components/views/Contact";
import { BrowserRouter, Route } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Navbar />
        <Route exact path='/'>
          <Home />
        </Route>
        <Route exact path='/contact'>
          <Contact />
        </Route>
        <Route path='/about'>
          <About />
        </Route>
        <Route exact path='client'>
          <Client />
        </Route>
        <Route exact path='/services'>
          <Services />
        </Route>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
