import React from "react";
import M from "materialize-css";
import Aos from "aos";
import { useTranslation } from "react-i18next";
const About = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
    Aos.init();
    M.AutoInit();
    window.scrollTo(0, 0);
    document.title = "Tashtech | About ";
  }, []);
  return (
    <React.Fragment>
      <section className='aboutpage'>
        <div className='row m0'>
          <div className='col m6 s12 p0'>
            <div className='aboutleft' data-aos='fade-left' data-aos-duration='500'>
              <h3 data-aos='fade-up' data-aos-duration='3000'>
                {t("about-banner.1")}
              </h3>
            </div>
          </div>
          <div className='col s12 m6 p1'>
            <div className='aboutright'>
              <img src='./images/aboutbanner.jpg' alt='aboutbanner' />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col s12'>
            <ul className='tabs'>
              <li className='tab col s3'>
                <a href='#test1' className='active'>
                  {t("about-overview.0")}
                </a>
              </li>
              <li className='tab col s3'>
                <a href='#test2'>{t("about-how-we-work.0")}</a>
              </li>

              <li className='tab col s3'>
                <a href='#test4'>{t("about-who-we-are.0")}</a>
              </li>
            </ul>
          </div>
          <div id='test1' className='col s12'>
            <p className='tab-text-content'>{t("about-overview.1")}</p>
          </div>
          <div id='test2' className='col s12'>
            <p className='tab-text-content'>{t("about-how-we-work.1")}</p>
          </div>

          <div id='test4' className='col s12'>
            <p className='tab-text-content'>{t("about-who-we-are.1")}</p>
          </div>
        </div>
      </section>
      <section className='messagefromdirector'>
        <div className='container'>
          <div className='row'>
            <div className='col l12 s12'>
              <h3 className='text-center'>{t("about-desk.1")}</h3>
            </div>
          </div>
          <div className='row'>
            <div className='col s12 m6'>
              <div className='message-content'>
                <i
                  className='fas fa-quote-left'
                  style={{ fontSize: "35px", color: "#f35c27" }}></i>
                <p>{t("about-desk.2")}</p>
                <i
                  className='fas fa-quote-right'
                  style={{ fontSize: "35px", color: "#f35c27" ,float:'right'}}></i>
                <p className='p0'>{t("about-desk.3")}</p>
                <span>- Ivan Pillay</span>
              </div>
            </div>
            <div className='col s12 m6'>
              <figure>
                <img src='./images/director.jpeg' alt='director' />
                <h6 style={{ textAlign: "center", fontSize: "20px" }}>- Ivan Pillay</h6>
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className='vision-section'>
        <div className='container'>
          <div className='row'>
            <div className='col s12 l12'>
              <div className='vision-title'>
                <h2>{t("about-our-vision.1")}</h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col s12 l12'>
              <div className='vision-content'>
                <p>{t("about-our-vision.2")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='vision-section'>
        <div className='container'>
          <div className='row'>
            <div className='col s12 l12'>
              <div className='vision-title'>
                <h2>{t("about-our-mission.1")}</h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col s12 l12'>
              <div className='vision-content'>
                <p>{t("about-our-mission.2")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default About;
