import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import M from "materialize-css";
const Navbar = () => {
  const { t, i18n } = useTranslation();
  React.useEffect(() => {
    document.getElementById("dropdown-content").innerHTML = localStorage.getItem(
      "i18nextLng"
    );
    document.getElementById("dropdown-content1").innerHTML = localStorage.getItem(
      "i18nextLng"
    );
    M.AutoInit();
  }, []);

  const changeLanguage = (type) => {
    i18n.changeLanguage(type);
  };

  return (
    <React.Fragment>
      <nav className='navbar-header' style={{ position: "fixed" }}>
        <div className='nav-wrapper'>
          <Link to='/' className='brand-logo'>
            <img className='logo-image' src='./images/logotashtech.png' alt='' />
          </Link>
          <img className='logo-text' src='./images/texttashtech.png' alt='' />
          <Link className='sidenav-trigger right' data-target='mobile-nav' to='#'>
            <i className='material-icons'>menu</i>
          </Link>

          <ul className='right hide-on-med-and-down '>
            <li>
              <Link to='/'>{t("navbar.1")}</Link>
            </li>
            <li>
              <Link to='about'>{t("navbar.2")}</Link>
            </li>
            <li>
              <Link to='services'>{t("navbar.3")}</Link>
            </li>
            {/* <li>
              <Link to='client'>Our Client</Link>
            </li> */}
            <li>
              <Link to='contact'>{t("navbar.4")}</Link>
            </li>
            <li
              className='waves-effect waves-light btn dropdown-trigger'
              data-target='dropdown1'>
              <span id='dropdown-content'>EN</span>
              <i className='fas fa-language right'></i>
            </li>
          </ul>
        </div>
      </nav>
      <ul id='dropdown1' className='dropdown-content'>
        <li>
          <span
            onClick={() => {
              changeLanguage("en");
              document.getElementById("dropdown-content").innerHTML = "EN";
            }}>
            English
          </span>
        </li>
        <li className='divider' tabIndex='-1'></li>
        <li>
          <span
            onClick={() => {
              changeLanguage("de");
              document.getElementById("dropdown-content").innerHTML = "DE";
            }}>
            Germany
          </span>
        </li>
        <li className='divider' tabIndex='-1'></li>
        <li>
          <span
            onClick={() => {
              changeLanguage("ja");
              document.getElementById("dropdown-content").innerHTML = "JA";
            }}>
            Japanese
          </span>
        </li>
      </ul>
      <ul id='dropdown2' className='dropdown-content'>
        <li>
          <span
            onClick={() => {
              changeLanguage("en");
              document.getElementById("dropdown-content1").innerHTML = "EN";
            }}>
            English
          </span>
        </li>
        <li className='divider' tabIndex='-1'></li>
        <li>
          <span
            onClick={() => {
              changeLanguage("de");
              document.getElementById("dropdown-content1").innerHTML = "DE";
            }}>
            Germany
          </span>
        </li>
        <li className='divider' tabIndex='-1'></li>
        <li>
          <span
            onClick={() => {
              changeLanguage("ja");
              document.getElementById("dropdown-content1").innerHTML = "JA";
            }}>
            Japanese
          </span>
        </li>
      </ul>

      <ul className='sidenav right' id='mobile-nav'>
        <li>
          <Link to='/' className='sidelink'>
            {t("navbar.1")}
          </Link>
        </li>
        <li>
          <Link to='about' className='sidelink'>
            {t("navbar.2")}
          </Link>
        </li>
        <li>
          <Link to='services' className='sidelink'>
            {t("navbar.3")}
          </Link>
        </li>
        {/* <li>
              <Link to='client'>Our Client</Link>
            </li> */}
        <li>
          <Link to='contact' className='sidelink'>
            {t("navbar.4")}
          </Link>
        </li>
        <li
          className='waves-effect waves-light btn dropdown-trigger'
          data-target='dropdown2'>
          <span id='dropdown-content1'>EN</span>
          <i className='fas fa-language right'></i>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default Navbar;
